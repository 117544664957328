.projectItem {
    height: 60px;
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    cursor: pointer;
}

.stateColumn {
    width: 50px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
}

.nameColumn {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #e0e0e0;
}

.durationColumn {
    width: 120px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.createdAtColumn {
    width: 150px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.projectItemState {
    width: 60px;
    display: flex;
    justify-content: center;
}

.projectItemName {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin: 0;
    flex: 1;
}

.projectItemDuration {
    font-size: 14px;
    font-weight: 600;
    color: #636363;
    margin: 0;
}

.loadingCircle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3298f1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.projectViewButton {
    background-color: #3298f1;
    padding: 6px 12px;
    border-radius: 10px;;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    transition: 100ms;
}

.projectViewButton:hover {
    filter: brightness(0.85);
}