.uploadVideoContainer {
    /* background-color: rgb(50, 152, 241);
    border-radius: 10px; */
    background-color: #FFFFFF;
    /* border: 2px solid #24A0ED; */
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 400px;
    width: 70vw;
}

.segmentedControl {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.options {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.optionButton {
    padding: 6px 10px;
    padding-bottom: 11px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.optionButton.active {
    border-bottom: 3px solid #24A0ED;
    padding-bottom: 8px;
    color: #24A0ED;
    font-weight: 600;
}

.inputSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.input {
    width: 90%;
    height: 39px;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 13px;
    box-sizing: border-box;
}

.input:focus {
    outline: none;
}

.submitError {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: red;
}