.basePage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
}

.homeHero {
    background-color: rgb(50, 152, 241);
}

.homeHeroSection {
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 100px 20px;
    box-sizing: border-box;
}

.homeSection {
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    padding: 70px 20px;
    box-sizing: border-box;
}

.homeSectionTitle {
    display: flex;
    align-items: center;
    gap: 8px;
}

.homeSectionTitleBar {
    width: 2px;
    height: 18px;
    background-color: #3298F1;
}

.homeSectionContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.homeSectionTextContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.homeHeroTextContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    max-width: 90%;
}

.homeHeroText {
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
}

.homeSectionTitle {
    color: #3298F1;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.homeSectionHeaderText {
    color: #000000;
    font-size: 25px;
    font-weight: 600;
    margin: 0;
}

.homeSectionDetailText {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 1.5;
}

.cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    margin: 40px 0px;
    box-sizing: border-box;
}

.card {
    flex: 1 1 calc(25% - 16px); /* Each card takes up 25% of the parent minus gaps */
    min-width: 400px; /* Ensures a minimum width before wrapping */
    max-width: calc(25% - 16px);
    text-align: center;
    /* border: 1px solid #3298F1; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(115, 115, 115, 0.28) 0px 0px 8px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.smallCard {
    flex: 1 1 calc(25% - 16px); /* Each card takes up 25% of the parent minus gaps */
    min-width: 200px; /* Ensures a minimum width before wrapping */
    max-width: calc(25% - 16px);
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(115, 115, 115, 0.28) 0px 0px 8px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cardTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.cardTitle {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.cardText {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
}

/* Ensure 2 cards per row at medium screen sizes */
@media (max-width: 1024px) {
    .card {
        flex: 1 1 calc(50% - 16px); 
        max-width: calc(50% - 16px);
    }

    .smallCard {
        flex: 1 1 calc(50% - 16px); 
        max-width: calc(50% - 16px);
    }
}

/* Ensure 1 card per row on smaller screens */
@media (max-width: 600px) {
    .card {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .smallCard {
        flex: 1 1 100%;
        max-width: 100%;
    }
}