.grayModalBackground {
	background: rgba(35, 35, 35, 0.6);
	z-index: 1;
	position: fixed;
    top: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.orderingBase {
    width: 400px;
    background-color: #FFFFFF;
    border-radius: 10px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    padding: 25px;
}

.modalContainer {
    background-color: #FFFFFF;
	border-radius: 12px;
	min-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
    position: relative;
}

.modalHeaderContainer {
	display: flex;
	align-items: center;
	gap: 15px;
    width: 100%;
    box-sizing: border-box;
}

.modalHeaderTitle {
    flex: 1;
    text-align: center;
}

.modalContentContainer {
	display: flex;
	flex-direction: column;
    align-items: center;
	gap: 20px;
    box-sizing: border-box;
}

.modalLabel {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}