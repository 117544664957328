.basePage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F5F5F5;
    overflow: hidden;
}

.content {
    padding: 0px 20px;
    padding-bottom: 20px;
    width: 100%;
    flex: 1;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    gap: 30px;
    box-sizing: border-box;
}

.mainContent {
    background-color: #FFFFFF;
    border-radius: 10px;
    flex: 1;
    padding: 20px;
    border: 1px solid #e9e9e9;
}

.actionsBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.backButton {
    cursor: pointer;
    transition: 100ms;
    display: flex;
    align-items: center;
    gap: 3px;
}

.backButton:hover {
    filter: brightness(0.85);
}

.backButtonText {
    font-size: 14px;
    font-weight: 600;
    color: #3298F1;
    margin: 0;
}

.downloadButton {
    background-color: rgb(50, 152, 241);
	border: none;
	border-radius: 20px;
	padding: 10px 12px 10px 16px;
	text-decoration: none;
	cursor: pointer;
	transition: 100ms;
	color: #FFFFFF;
	font-size: 15px;
    margin: 0;
	font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
}

.downloadButton:hover {
    filter: brightness(0.9);
}

.projectSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    margin-top: 30px;
    height: 100%;
    box-sizing: border-box;
}

.projectSectionHeader {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin: 0;
}

.loadingNamePlaceholder {
    width: 300px;
    height: 40px;
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.loadingVideoPlaceholder {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.loadingGradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: gradientMove 1.5s infinite;
}

@keyframes gradientMove {
    from {
        background-position: 200% 0;
    }
    to {
        background-position: -200% 0;
    }
}

.videoPlayerContainer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.videoPlayer {
    /* height: 100%; */
    max-width: 900px;
    border-radius: 10px;
}