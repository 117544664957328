.basePage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F5F5F5;
}

.content {
    padding: 0px 20px;
    padding-bottom: 20px;
    width: 100%;
    flex: 1;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    gap: 30px;
    box-sizing: border-box;
    max-height: calc(100vh - 78px);
}

.mainContent {
    background-color: #FFFFFF;
    border-radius: 10px;
    flex: 1;
    padding: 20px;
    border: 1px solid #e9e9e9;
    overflow: hidden;
    flex-grow: 1;
}

.projectListSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-grow: 1;
    max-height: 100%; /* Ensures it doesn’t exceed the parent’s height */
    overflow: hidden;
}

.projectListSectionHeader {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin: 0;
}

.projectsList {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectsTableWrapper {
    overflow-y: auto; /* Enables vertical scrolling */
    width: 100%; /* Ensures the wrapper takes full width */
}

.projectsTable {
    display: table;
    width: 100%;
    border-collapse: collapse;
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 100%;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.projectsTable::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari */
}

.projectsTableStatusColumn {
    width: 50px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
}

.projectsTableNameColumn {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
}

.projectsTableDurationColumn {
    width: 120px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
}

.projectsTableCreatedAtColumn {
    width: 150px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
}

.projectsTableHeaderText {
    color: #939393;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    text-align: left;
}

.loadingCircle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3298F1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}