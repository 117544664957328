.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    color: black;
}

.navbarContent {
    max-width: 1080px;
    padding: 0px 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 78px;
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo svg {
    fill: none;
    stroke: white;
    background-color: rgb(50, 152, 241);
    padding: 5px;
    border-radius: 10px;
}

.brandName {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

.actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    transition: transform 0.3s ease;
}

.actions.open {
    transform: translateY(0);
}

.signInButton {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    color: black;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}