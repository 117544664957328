.sidebar {
    width: 250px;
}

.sidebarList {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.sidebarItem {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #828282;
    padding: 8px 10px;
    cursor: pointer;
}

.sidebarItemSelected {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000000;
    padding: 8px 10px;
    background-color: #DADADA;
    border-radius: 10px;;
}

.sidebarItemName {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}