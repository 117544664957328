.paymentItem {
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    height: 40px;
}

.paymentItemColumn {
    border-bottom: 1px solid #e0e0e0;
}

.paymentItemColumnContentLeftAligned {
    padding: 8px 12px 8px 0px;
    text-align: left;
}

.amountColumn {
    padding: 8px 12px 8px 0px;
    width: 60px;
}

.currencyColumn {
    padding: 8px 12px 8px 0px;
    width: 30px;
}

.statusColumn {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 8px 12px 8px 0px;
    width: 50px;
}

.nameColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 12px 8px 0px;
}

.durationColumn {
    text-align: left;
    padding: 8px 12px 8px 0px;
}

.createdAtColumn {
    text-align: left;
    padding: 8px 12px 8px 0px;
}

.boldText {
    font-size: 13px;
    font-weight: 600;
    color: black;
    margin: 0;
}

.regularText {
    font-size: 13px;
    font-weight: 500;
    color: #636363;
    margin: 0;
}

.succeededStatus {
    background-color: rgb(209, 250, 179);
    border-radius: 5px;
    padding: 4px 8px;
    border: 1px solid rgb(164, 224, 164);
    display: flex;
    align-items: center;
    gap: 4px;
}

.succeededStatusText {
    font-size: 12px;
    font-weight: 500;
    color: #0e610e;
    margin: 0;
}

.paymentMethodContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.paymentMethodDotsNumbers {
    display: flex;
    align-items: center;
    gap: 5px;
}

.paymentMethodDots {
    display: flex;
    align-items: center;
    gap: 3px;
}

.loadingCircle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3298f1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.paymentViewButton {
    background-color: #3298f1;
    padding: 6px 12px;
    border-radius: 10px;;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    transition: 100ms;
}

.paymentViewButton:hover {
    filter: brightness(0.85);
}