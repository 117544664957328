.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    color: black;
}
  
.footer.border {
    border-bottom: 1px solid #DADADA;
}
  
.footerContent {
    max-width: 1080px;
    padding: 0px 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 78px;
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo svg {
    fill: none;
    stroke: white;
    background-color: rgb(50, 152, 241);
    padding: 5px;
    border-radius: 10px;
}

.brandName {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}