@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.base-page.blue-gradient {
  background: linear-gradient(180deg, #D3EEFF, #d0eafb00);
}

/* Navbar */

.navbar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.navbar.border {
  border-bottom: 1px solid #DADADA;
}

.navbar-container {
  max-width: 1112px;
  width: 100%;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-left-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.navbar-logo {
  background-color: rgb(50, 152, 241);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar-menu-item {
  cursor: pointer;
}

.navbar-menu-item-button {
  color: black;
  font-size: 15px;
  font-weight: 600;
  transition: 200ms;
}

.navbar-menu-item-button:hover {
  color: rgb(50, 152, 241);
}

.navbar-right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

/* Project Navbar */

.project-navbar {
  width: 85%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.project-navbar-container {
  width: 100%;
  padding: 20px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-navbar-left-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.project-navbar-center-container {
  text-align: center;
}

.project-navbar-center-text-container {
  display: flex;
  align-items: center;
}

.project-navbar-center-your-projects-link-text {
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #000000;
  line-height: 28px;
}

.project-navbar-right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

/* Project Footer */

.project-footer {
  margin-top: 20px;
  width: 85%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.project-footer-container {
  width: 100%;
  padding: 20px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-footer-left-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

/* Unauthenticated Home */

.unauth-home-content {
  max-width: 1112px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

/* Unauthenticated Home Hero Content */

.unauth-home-hero-section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
}

.unauth-home-hero-section-main-text-container {
  margin-bottom: 10px;
}

.unauth-home-hero-section-secondary-text-container {
  width: 70%;
  margin-bottom: 30px;
}

/* Unauthenticated Home Content Section */

/* Unauthenticated Home Demo Content Section */

.unauth-home-demo-content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.unauth-home-demo-video-section {
  width: 700px;
  height: 450px;
  border-radius: 15px;
}

.unauth-home-demo-video {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.unauth-home-demo-video-tabs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.unauth-home-demo-video-tab {
  padding: 6px 14px;
  border-radius: 6px;
  border: 1px solid #DADADA;
  cursor: pointer;
}

.unauth-home-demo-video-tab.active {
  border: 2px solid rgb(50, 152, 241);
}

.unauth-home-definition-content-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgb(247, 249, 252);
  padding: 20px;
  border-radius: 10px;
}

.unauth-home-products-content-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}

.unauth-home-service-content-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-image: linear-gradient(to bottom right, rgb(50, 152, 241), rgb(97, 171, 236));
  padding: 20px;
  border-radius: 10px;
  flex: 1;
}

.unauth-home-service-reasons-content-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 10px 0px 15px 10px;
}

.unauth-home-service-reason {
  display: flex;
  gap: 10px;
}

.unauth-home-service-reason-checkbox {
  /* border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #FFFFFF;
  width: 18px;
  height: 18px; */
}

.unauth-home-service-contact-sales-button {
  background-color: #FFFFFF;
  padding: 10px 12px 10px 16px;
  border-radius: 50px;
  color: rgb(50, 152, 241);
  cursor: pointer;
  transition: 100ms;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}

.unauth-home-service-contact-sales-button:hover {
  filter: brightness(0.9);
}

.unauth-home-api-content-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgb(247, 249, 252);
  padding: 20px;
  border-radius: 10px;
  flex: 1;
}

.unauth-home-api-reason-checkbox {
  /* border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000; */
}

.unauth-home-api-contact-sales-button {
  background-color: rgb(50, 152, 241);
  padding: 10px 12px 10px 16px;
  border-radius: 50px;
  color: #FFFFFF;
  cursor: pointer;
  transition: 100ms;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}

.unauth-home-api-contact-sales-button:hover {
  filter: brightness(0.9);
}

/* Unauth Home Benefits Content Section */

.unauth-home-benefits-content-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgb(247, 249, 252);
  padding: 20px;
  border-radius: 10px;
}

.unauth-home-benefits-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}

.unauth-home-benefits-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 18px 36px -18px rgba(0,0,0,0.1),0px 30px 45px -30px rgba(50,50,93,0.25);
}

.unauth-home-benefits-card-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Buttons */

.create-project-button {
  background-color: rgb(50, 152, 241);
  padding: 10px 12px 10px 16px;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  transition: 100ms;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}

.create-project-button:hover {
  filter: brightness(0.9);
}

.contact-sales-button {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: black;
  font-size: 15px;
  font-weight: 600;
  transition: 200ms;
}

.contact-sales-button:hover {
  color: rgb(50, 152, 241);
}

.full-width-button {
  border-radius: 12px;
  padding: 14px 20px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  color: #FFFFFF;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s ease;
}

.full-width-button.disabled {
  cursor: auto;
  padding: 8px 0px;
}

/* Text */

.black-header-1-text {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  color: #000000;
}

.black-header-2-text {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #000000;
}

.black-header-3-text {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  color: #000000;
}

.black-header-4-text {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #000000;
}

.black-header-5-text {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #000000;
}

.black-regular-4-text {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #000000;
  line-height: 28px;
}

.black-regular-5-text {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #000000;
  line-height: 20px;
}

.gray-regular-4-text {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  color: #848484;
}

.blue-header-5-text {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: #3298F1;
}

.blue-regular-4-text {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  color: #3298F1;
  text-decoration: none;
  cursor: pointer;
}

.red-regular-5-text {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: rgb(193, 53, 21);
}

.green-regular-5-text {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: rgb(0, 211, 0);
  line-height: 28px;
}

.white-header-2-text {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #FFFFFF;
}

.white-header-5-text {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: #FFFFFF;
}

.white-regular-4-text {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #FFFFFF;
  line-height: 20px;
}

/* ProjectGrid */

.project-grid {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 25px 35px;
  box-sizing: border-box;
  height: calc(100vh - 174px);
}

.project-grid-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  transition: 100ms;
  border-radius: 10px;
  flex-basis: 25%;
  max-width: 319px;
  padding: 15px;
  /* max-width: 25%; */
}

.project-grid-item:hover {
  background-color: #f5f5f5;
}

.project-grid-item-image-container {
  width: 100%;
  height: 190px;
  background-color: #DADADA;
  border-radius: 10px;
  position: relative;
}

.project-grid-item-image {
  z-index: 3;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.project-grid-item-status {
  display: flex;
  align-items: center;
  gap: 7px;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 6px;
  opacity: 0.9;
  padding: 1px 6px;
  background-color: #FFFFFF;
  z-index: 5;
}

.project-grid-item-ready-circle {
  background-color: rgb(0, 211, 0);
  border-radius: 50%;
  width: 7px;
  height: 7px;
}

.project-grid-item-processing-circle {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgb(50, 152, 241); /* Change the color as needed */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

/* Project */

.project-base {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-content {
  padding: 20px 36px;
  display: flex;
  gap: 40px;
  height: calc(100vh - 214px);
  width: 80%;
}

.project-video-track-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.project-video {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.project-descriptions {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  overflow-y: scroll;
  border: 1px solid #DADADA;
  border-radius: 15px;
  padding: 10px;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.project-descriptions::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.project-description {
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
}

/* Create Project Modal */

.create-project-modal-base {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.create-project-modal-error-message {
  display: flex;
  align-items: center;
  gap: 7px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.form-level {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
}

.form-text-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.form-selectable-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 13px;
  border: 1px solid #DADADA;
  background-color: #F3F3F3;
  border-radius: 12px;
  max-width: 200px;
  cursor: pointer;
}

.form-selectable-field.active {
  background-color: rgba(126, 153, 125, 0.2);
  border: 1px solid #609C5E;
}

/* Text Input Box */

.textInputBox {
  padding: 12px 14px;
  background-color: #F3F3F3;
	border: none;
	border: 1px solid #DADADA;
	border-radius: 12px;
	font-size: 15px;
	text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.textInputBox:focus {
	outline: none;
	border: 1px solid rgb(50, 152, 241);
}

.textInputBox.red {
  background-color: rgb(254, 248, 246);
  border: 1px solid rgb(193, 53, 21);
}

.textInputBox.red:focus {
	outline: none;
	border: 1px solid rgb(193, 53, 21);
}
