.basePage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F5F5F5;
}

.content {
    padding: 0px 20px;
    padding-bottom: 20px;
    width: 100%;
    flex: 1;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    gap: 30px;
    box-sizing: border-box;
}

.mainContent {
    background-color: #FFFFFF;
    border-radius: 10px;
    flex: 1;
    padding: 20px;
    border: 1px solid #e9e9e9;
}

.supportSection {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.supportSectionHeader {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin: 0;
}

.supportSectionText {
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin: 0;
}

.supportForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    max-width: 400px;
}

.supportFormGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.supportFormGroupLabelText {
    color: black;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.supportErrorMessage {
	display: flex;
  	align-items: center;
  	gap: 7px;
}

.loadingCircle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3298F1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}