.describeVideoButton {
    background-color: rgb(50, 152, 241);
	border: none;
	border-radius: 20px;
	padding: 10px 12px 10px 16px;
	text-decoration: none;
	cursor: pointer;
	transition: 100ms;
	color: #FFFFFF;
	font-size: 15px;
	font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
}

.describeVideoButton:hover {
    filter: brightness(0.9);
}

.describeVideoButtonText {
    color: white;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}