.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    color: black;
}

.navbarContent {
    padding: 0px 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-height: 78px;
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo svg {
    fill: none;
    stroke: white;
    background-color: rgb(50, 152, 241);
    padding: 5px;
    border-radius: 10px;
}

.brandName {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

.actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    transition: transform 0.3s ease;
}

.actions.open {
    transform: translateY(0);
}

.signInButton {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    color: black;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}

.hamburgerMenu {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

@media (max-width: 768px) {
    .actions {
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 10px;
        background: #282c34;
        padding: 10px;
        border-radius: 5px;
        transform: translateY(-150%);
    }

    .actions.open {
        transform: translateY(0);
    }

    .hamburgerMenu {
        display: block;
    }
}

.userSettingsDropdown {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.userSettingDropdownButton {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 3px;
	cursor: pointer;
	padding: 8px;
    background-color: #DADADA;
    border-radius: 50%;
    transition: 100ms;
}

.userSettingDropdownButton:hover {
    filter: brightness(0.85);
}

.userSettingDropdownContent {
	position: absolute;
	left: auto;
	right: 0;
	top: 50px;
	background-color: #FFFFFF;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 10;
    border: 1px solid #DADADA;
	border-radius: 10px;
	width: 150px;
	padding: 5px;
}

.userSettingDropdownItem {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    transition: 100ms;
    cursor: pointer;
    padding: 10px;
    border-radius: 6px;
}

.userSettingDropdownItem:hover {
    background-color: #DADADA;
}