.modalContainer {
	background-color: #FFFFFF;
	border-radius: 12px;
	min-width: 400px;
	max-width: 450px;
    box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
    position: relative;
}

.modalLogo {
	background-color: rgb(50, 152, 241);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.modalHeaderContainer {
	display: flex;
	align-items: center;
	gap: 15px;
    width: 100%;
    padding: 24px 24px;
    box-sizing: border-box;
}

.modalHeaderTitle {
    flex: 1;
    text-align: center;
}

.modalContentContainer {
	padding: 24px;
	min-width: 400px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 20px;
}

.modalClose {
    position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.modalSubmitButton {
	background-color: rgb(50, 152, 241);
	border: none;
	border-radius: 20px;
	padding: 10px 12px 10px 16px;
	text-decoration: none;
	cursor: pointer;
	transition: 150ms;
	color: #FFFFFF;
	font-size: 13px;
	font-weight: 600;
	display: flex;
    align-items: center;
    gap: 5px;
}

.modalSubmitButton:hover {
	opacity: 0.8;
}

.modalSecondaryActionContainer {
	margin-top: 40px;
}

.loadingCircle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3298F1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}