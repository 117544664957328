.authBase {
    background-color: rgb(50, 152, 241);
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.authForm {
    display: flex;
    flex-direction: column;
    gap: 25px;
	width: 100%;
}

.authInputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.authGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.authErrorMessage {
	display: flex;
  	align-items: center;
  	gap: 7px;
}

.authSecondaryAction {
	display: flex;
	align-items: center;
	gap: 4px;
	padding-top: 20px;
}