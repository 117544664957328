.basePage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F5F5F5;
}

.content {
    padding: 0px 20px;
    padding-bottom: 20px;
    width: 100%;
    flex: 1;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    gap: 30px;
    box-sizing: border-box;
}

.mainContent {
    background-color: #FFFFFF;
    border-radius: 10px;
    flex: 1;
    padding: 20px;
    border: 1px solid #e9e9e9;
}

.paymentsSection {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.paymentsSectionHeader {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin: 0;
}

.noPaymentsText {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.loadingCircle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3298F1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.paymentsTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.paymentsTableHeaderColumn {
    border-bottom: 1px solid #cecece;
    white-space: nowrap;
}

.paymentsTableAmountColumn {
    padding: 8px 12px 8px 0px;
    text-align: right;
    margin: 0;
    width: 60px;
}

.paymentsTableCurrencyColumn {
    padding: 8px 12px 8px 0px;
    width: 30px;
}

.paymentsTableStatusColumn {
    padding: 8px 12px 8px 0px;
    width: 50px;
}

.paymentsTableProjectNameColumn {
    padding: 8px 12px 8px 0px;
}

.paymentsTableDurationColumn {
    padding: 8px 12px 8px 0px;
}

.paymentsTablePaymentMethodColumn {
    white-space: nowrap;
    padding: 8px 12px 8px 0px;
}

.paymentsTableDateColumn {
    white-space: nowrap;
    padding: 8px 12px 8px 0px;
}

.paymentsTableHeaderText {
    color: #939393;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    text-align: left;
}